import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tabs,
  Tab,
  Snackbar,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { parse } from "csv-parse/browser/esm/sync";
import { darken, lighten, styled } from "@mui/material/styles";

export default function RequestCardsDialog(props) {
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [embossType, setEmbossType] = useState("one");
  const [tabSelected, setTabSelected] = React.useState(0);
  const [csvData, setCsvData] = useState([]);
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  async function fetchLocations() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/locations`
    );
    return response;
  }

  async function fetchEmployerInfo() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer`
    );
    return response;
  }

  const {
    data: dataLocations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
  } = useQuery(["locations"], fetchLocations);

  const {
    data: dataEmployer,
    isLoading: isFetchingEmployer,
    isError: isErrorEmployer,
  } = useQuery(["employerInfo"], fetchEmployerInfo);

  const validationSchema = yup.object({
    description: yup
      .string("Enter the description for this request")
      .required("A description is required")
      .max(30, "Max length is 30"),
    embossPart1: yup
      .string("Enter the Embossing (Part 1)")
      .required("Emboss part 1 is required")
      //.max(9,"Max length is 9")
      .matches(/^[a-zA-Z\s]+$/, "Only letters and numbers are allowed")
      .test(
        "embossing_length",
        "The embossing max length is 19",
        (value) =>
          formik.values.embossPart1.length + formik.values.embossPart2.length <
          18
      ),
    embossPart2: yup
      .string("Enter the Embossing (Part 2)")
      .required("Emboss part 2 is required")
      //.max(9,"Max length is 9")
      .matches(/^[a-zA-Z\s]+$/, "Only letters and numbers are allowed")
      .test(
        "embossing_length",
        "The embossing lenght is calculated using the two fields",
        (value) =>
          formik.values.embossPart1.length + formik.values.embossPart2.length <
          18
      ),
    embossPart3: yup
      .string("Enter the Embossing (Part 3)")
      .max(19, "Max length is 19")
      .matches(
        /^[0-9a-zA-Z\s-]+$/,
        "Only letters, numbers and dashes are allowed"
      ),
    quantity: yup
      .number("Enter how many cards do you want")
      .required("quantity is required")
      .min(1, "Min quantity is 1")
      .max(500, "Max quantity is 500"),
    idLocation: yup
      .string("Select the location")
      .required("Location is required")
      .test(
        "must_be_selected",
        "You need to select a location",
        (value) => value !== "0"
      ),
  });

  const queryClient = useQueryClient();

  const validateEmbossing = (row) => {
    var regexFirstLineEmbossing = /^[A-Za-z]+$/;
    var regexSecondLineEmbossing = /^[A-Z a-z0-9]+$/;

    var isValid = regexFirstLineEmbossing.test(row.embossPart1);
    if (!isValid) return "Line 1 first part only allow A-Z";

    isValid = regexFirstLineEmbossing.test(row.embossPart2);
    if (row.embossPart2 && !isValid)
      return "Line 1 second part only allow A-Z (uppercase)";

    if (row?.embossPart1.length + row?.embossPart2?.length > 18)
      return "Line 1 max 19 characters";

    isValid = regexSecondLineEmbossing.test(row.embossPart3);
    if (row.embossPart3 && !isValid) return "Line 2 only allow A-Z 0-9";

    regexFirstLineEmbossing = /^[A-Z 0-9]{1,19}$/;
    isValid = regexSecondLineEmbossing.test(row.embossPart3);
    if (row.embossPart3 && !isValid) return "Line 2 max 19 characters";

    return "";
  };

  const createBundleMutation = useMutation(
    (newBundle) => {
      newBundle.embossPart3 = embossType === "two" ? newBundle.embossPart3 : "";
      return HttpService.getAxiosClient().put(
        window.API_URL + `/paycards/bundle`,
        newBundle
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["bundles"] });
        handleClose();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        //   setFeedback({
        //   open: true,
        //   type: "error",
        //   message: msg,
        // });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      description: "",
      embossPart1: "VALUED",
      embossPart2: "MEMBER",
      embossPart3: dataEmployer?.data.name.substr(0, 18),
      quantity: "",
      idLocation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (tabSelected === 0) {
        if (embossType === "two" && values.embossPart3 === undefined)
          values = { ...values, embossPart3: dataEmployer?.data.name };
      }
      if (tabSelected === 1) {
        if (rowsWithIndex.length === 0) {
          setFeedback({
            open: true,
            type: "error",
            message:
              "Embossing list is not loaded, please upload the embossing list in CSV",
          });
          return;
        }
        if (rowsWithIndex.length !== values.quantity) {
          setFeedback({
            open: true,
            type: "error",
            message:
              "The quantity does not correspond to the number of lines in the uploaded CSV",
          });
          return;
        }

        if (!rowsWithIndex.every((obj) => obj.status === "")) {
          setFeedback({
            open: true,
            type: "error",
            message:
              "There are issues with the validation of the lines from the CSV. Please review the status field, where the red ones refer to the ones with issues.",
          });
          return;
        }

        values.embossPart1 = "";
        values.embossPart2 = "";
        values.embossPart3 = "";
        values = { ...values, embossList: rowsWithIndex };
      }
      createBundleMutation.mutate({ ...values });
    },
  });

  const handleLocationChange = (event, idLocation) => {
    setSelectedLocation(idLocation.props.value);
    formik.setFieldValue("idLocation", idLocation.props.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };

  const handleChangeEmbossType = (event) => {
    event.persist();
    setEmbossType(event.target.value);
  };

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
      setSelectedLocation("0");
    }
  }, [props.open]);

  const handleTabChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 15,
    },
    {
      field: "embossPart1",
      headerName: "Line 1 (first part)",
      width: 130,
    },
    {
      field: "embossPart2",
      headerName: "Line 1 (second part)",
      width: 130,
    },
    {
      field: "embossPart3",
      headerName: "Line 2",
      width: 230,
    },
    { field: "status", headerName: "Status", width: 300 },
  ];

  const rowsWithIndex = csvData.map((row, index) => ({
    ...row,
    embossPart1: row.embossPart1.toUpperCase(),
    embossPart2: row.embossPart2.toUpperCase(),
    id: index + 1,
    status: validateEmbossing(row),
  })); // Add id property using index

  const renderEmbossing = () => {
    const handleFileUpload = (e) => {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (evt) => {
        if (!evt?.target?.result) {
          return;
        }
        const { result } = evt.target;
        const records = parse(result.replace("ï»¿", ""), {
          columns: ["embossPart1", "embossPart2", "embossPart3"],
          relax_column_count: true,
          delimiter: ",",
          trim: true,
          // skip_empty_lines: true,
        });
        setCsvData(records);
      };
      reader.readAsBinaryString(file);
    };

    const getBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

    const getHoverBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

    const getSelectedBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

    const getSelectedHoverBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
      "& .super-app-theme--Rejected": {
        backgroundColor: getBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              theme.palette.error.main,
              theme.palette.mode
            ),
          },
        },
      },
    }));

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarExport />
        </GridToolbarContainer>
      );
    }

    return (
      <Fragment>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              border: "whitesmoke solid 1px",
              marginRight: "10px",
              height: "1px",
            }}
          ></div>
        </Grid>
        {dataEmployer !== null && dataEmployer !== undefined ? (
          <Fragment>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <Tabs
                    value={tabSelected}
                    onChange={handleTabChange}
                    aria-label="basic tabs"
                  >
                    <Tab label="Standard embossing" />
                    <Tab label="Personalized embossing" />
                  </Tabs>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ flex: "1 1 auto" }}>
                <div hidden={tabSelected !== 1}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: "1rem" }}
                  >
                    Upload CSV
                    <input
                      type="file"
                      accept=".csv"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<ClearIcon />}
                    sx={{ marginRight: "1rem" }}
                    onClick={(event) => {
                      // setFilename("");
                      setCsvData([]);
                      if (fileInputRef.current)
                        fileInputRef.current.value = null;
                      event.preventDefault();
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div
                  role="tabpanel"
                  hidden={tabSelected !== 1}
                  id={`simple-tabpanel-1`}
                  aria-labelledby={`simple-tab-1`}
                >
                  {tabSelected === 1 && (
                    <Box>
                      <Grid container>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} height="28rem">
                          {csvData ? (
                            <StyledDataGrid
                              density="compact"
                              rows={rowsWithIndex}
                              columns={columns}
                              getRowId={(row) => row.id}
                              pageSize={100}
                              rowsPerPageOptions={[100]}
                              disableSelectionOnClick
                              editable={false}
                              ref={dataGridRef}
                              components={{ Toolbar: CustomToolbar }}
                              componentsProps={{
                                toolbar: {
                                  showQuickFilter: false,
                                  quickFilterProps: { debounceMs: 800 },
                                },
                              }}
                              getRowClassName={(params) => {
                                if (params.row.status) {
                                  return "super-app-theme--Rejected";
                                }
                                return "";
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  role="tabpanel"
                  hidden={tabSelected !== 0}
                  id={`simple-tabpanel-0`}
                  aria-labelledby={`simple-tab-0`}
                >
                  {tabSelected === 0 && (
                    <Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControl>
                            <RadioGroup
                              style={{ fontSize: "8px", marginLeft: "10px" }}
                              aria-labelledby="emboss-radio-buttons-group-label"
                              defaultValue="two"
                              value={embossType}
                              row
                              name="radio-buttons-group"
                              fontSize="10px"
                              onChange={handleChangeEmbossType}
                            >
                              <FormControlLabel
                                sx={{
                                  "& .MuiTypography-root": { fontSize: 15 },
                                }}
                                value="one"
                                control={
                                  <Radio
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 18 },
                                    }}
                                  />
                                }
                                label="One Line"
                              />
                              <FormControlLabel
                                sx={{
                                  "& .MuiTypography-root": { fontSize: 15 },
                                }}
                                value="two"
                                control={
                                  <Radio
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 18 },
                                    }}
                                  />
                                }
                                label="Two lines"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            fullWidth
                            id="embossPart1"
                            label="Emboss (Line 1)"
                            value={formik.values.embossPart1}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.embossPart1 &&
                              Boolean(formik.errors.embossPart1)
                            }
                            helperText={
                              formik.touched.embossPart1 &&
                              formik.errors.embossPart1
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            fullWidth
                            id="embossPart2"
                            label="Emboss (Line 1)"
                            value={formik.values.embossPart2}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.embossPart2 &&
                              Boolean(formik.errors.embossPart2)
                            }
                            helperText={
                              formik.touched.embossPart2 &&
                              formik.errors.embossPart2
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 2 }}>
                          <div hidden={embossType !== "two"}>
                            <TextField
                              size="small"
                              fullWidth
                              id="embossPart3"
                              label="Emboss (Line 2)"
                              value={formik.values.embossPart3}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.embossPart3 &&
                                Boolean(formik.errors.embossPart3)
                              }
                              helperText={
                                formik.touched.embossPart3 &&
                                formik.errors.embossPart3
                              }
                            />
                          </div>
                        </Grid>
                        <div style={{ padding: "20px" }}>
                          <CreditCardIcon
                            style={{ marginRight: "10px" }}
                            color="primary"
                          />
                          <span>
                            This information will be printed in the back of the
                            card, as additional information.
                          </span>
                        </div>
                        <Grid item xs={12}>
                          <div
                            style={{
                              width: "100%",
                              border: "whitesmoke solid 1px",
                              marginRight: "10px",
                              height: "1px",
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </div>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  const fileInputRef = useRef(null);
  const dataGridRef = useRef(null);

  const dialogStyles = {
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: dialogStyles,
        sx: { maxWidth: "800px", width: "800px" },
      }}
    >
      <Box
        component="form"
        noValidate
        sx={{ height: "1" }}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        {isFetchingEmployer || isLoadingLocations ? (
          <CircularProgress color="inherit" />
        ) : (
          <Fragment>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent
              sx={{ height: "82%", display: "flex", flexDirection: "column" }}
            >
              <DialogContentText>{props.dialogContentText}</DialogContentText>
              <br />
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    id="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="quantity"
                    label="Quantity"
                    type="number"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.quantity && Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity && formik.errors.quantity
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    error={
                      formik.touched.idLocation &&
                      Boolean(formik.errors.idLocation)
                    }
                  >
                    <InputLabel id="idLocation">Location </InputLabel>
                    <Select
                      style={{ height: "40px", width: "200px" }}
                      labelId="idLocation"
                      id="simple-select"
                      label="Location"
                      onChange={handleLocationChange}
                      value={selectedLocation}
                    >
                      <MenuItem value={0}>-</MenuItem>
                      {dataLocations?.data.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.idLocation && formik.errors.idLocation}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {renderEmbossing()}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color={"error"}>
                Cancel
              </Button>
              <Button type="submit">{props.action}</Button>
            </DialogActions>
          </Fragment>
        )}
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
